import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-column: 2 / span 4;
  grid-template-columns: 0 repeat(4, minmax(auto, 1fr)) 0;
  grid-template-rows: max-content;
  gap: 16px;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 1 / span 10;
    grid-template-columns: 1fr repeat(8, minmax(0, 1fr)) 1fr;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 14;
    grid-template-columns: 1fr repeat(12, minmax(0, 1fr)) 1fr;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 2 / span 12;
    grid-template-columns: 1fr repeat(12, minmax(0, 1fr)) 1fr;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-template-columns: 1fr repeat(12, minmax(0, 96px)) 1fr;
  }
`

export default Container;