import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body,
  html {
    background: linear-gradient(120.58deg, #EDEDED 15.16%, rgba(245, 245, 245, 0) 81.37%);
    color: rgba(0,0,0,0.87);
    font-family: Roboto, sans-serif;
    line-height: 1.5;
    margin: 0;
    width: 100%;
    overflow: auto;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  body,
  body > div,
  body > div > div {
    width: 100%;
    min-height: 100%;
  }

  body > div > div {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 10px 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin: 10px 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0;
  }

  h4 {
    font-size: 20px;
    font-weight: 300;
    margin: 10px 0;
  }

  a {
    text-decoration: none;
  }

  p {
    font-size: 18px;
    margin: 0 0 10px 0;
    color: rgba(0,0,0,0.6);
    font-weight: 300;
    line-height: 1.5;
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }

  .nav-link {
    color: rgba(0,0,0,0.87);
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .nav-link:focus, 
  .nav-link:hover {
    text-decoration: underline;
  }

`

export default GlobalStyle