const linkResolver = (doc) => {

    if (doc.type === 'page') {
        return `/${doc.uid}`
    }
    else if (doc.type === 'contactpage') {
        return `/contact`
    }
        return '/'
}

module.exports = linkResolver
