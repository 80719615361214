import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Services from './NavigationServices'
import More from './NavigationMore'
import LogoSource from '../images/logo_eilandbeheer.svg'

import Section from './layout/Section'
import Container from './layout/Container'

const StyledSection = styled(Section)`
  border-top: 1px solid rgba(0,0,0,0.12);
  align-self: end;
  padding-top: 3rem;
  padding-bottom: 0;
`

const StyledContainer = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Address = styled.div`
  grid-column: 1 / span 6;
  color: ${(props) => props.theme.color.light};
  font-size: 16px;
  font-weight: 300;
  padding-top: 32px;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 4;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 11 / span 3;
    padding-top: 0;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 10 / span 3;
    padding-top: 0;
  }
`

const Legal = styled.div`
  grid-column: 1 / span 6;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
    flex-direction: row;
  }
`

const Copyright = styled.div`
  color: ${(props) => props.theme.color.light};
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
  padding-bottom: 16px;

  @media ${(props) => props.theme.breakpoints.l} {
    padding-bottom: 0;
  }
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.light};
  font-size: 14px;
  font-weight: 300;
  margin-left: 4px;

  :hover {
    text-decoration: underline;
  }
`

const DesignedBy = styled.div`
  color: ${(props) => props.theme.color.light};
  font-weight: 300;
`

const CompanyLogo = styled.img`
  margin-top: 14px;
  width: 160px;
`

const AddressList = styled.ul`
  padding 8px 0;
`

const AddressListItem = styled.li`
`

const getCurrentYear = () => {
  return new Date().getFullYear();  
};

const Footer = () => {

  return (
    <StyledSection as="footer">
      <StyledContainer>
        <Services />
        <More />
        <Address>
          <CompanyLogo src={LogoSource} alt="Eilandbouw logo" />
          <AddressList>
            <AddressListItem>Nieuwe Dijk 12B</AddressListItem>
            <AddressListItem>88181 HC West - Terschelling</AddressListItem>
          </AddressList>
          <AddressList>
            <AddressListItem>info@eilandbeheer.nl</AddressListItem>
            <AddressListItem>+31 (0) 6 5340 6977</AddressListItem>
          </AddressList>
          <AddressList>
            <AddressListItem>KvK: 63730294</AddressListItem>
            <AddressListItem>Bank: NL59KNAB0255282958</AddressListItem>
          </AddressList>
        </Address>
        <Legal>
          <Copyright>
            <span>Copyright © {getCurrentYear()} Eilandbeheer |</span>
            <StyledLink to="/privacystatement">
              Privacystatement
            </StyledLink>
          </Copyright>
          <DesignedBy>
            Design by 
            <StyledLink to="https://stuif.design">
              Stuif Design
            </StyledLink>
          </DesignedBy>
        </Legal>
      </StyledContainer>
    </StyledSection>
  ) 
}

export default Footer
