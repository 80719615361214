import styled from "styled-components";

const Section = styled.section`
  display: grid;
  grid-column: 1 / span 6;
  grid-template-columns: 0 repeat(4, minmax(0, 1fr)) 0;
  grid-template-rows: max-content;
  gap: 16px;
  margin: 0;
  padding: 2rem 0;

  @media ${(props) => props.theme.breakpoints.s} {
    display: grid;
    grid-column: 1 / span 10;
    grid-template-columns: 1fr repeat(8, minmax(0, 1fr)) 1fr;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    display: grid;
    grid-column: 1 / span 14;
    grid-template-columns: 1fr repeat(12, minmax(0, 1fr)) 1fr;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    display: grid;
    grid-column: 1 / span 14;
    grid-template-columns: 1fr repeat(12, minmax(0, 96px)) 1fr;
    padding: 4rem 0;
  }
`

export default Section;