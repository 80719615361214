import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'


const MoreLinks = styled.div`
  grid-column: 4 / span 3;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 6 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 5 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 6 / span 3;
  }
`

const MoreList = styled.div`
  display: flex;
  flex-direction: column;
`

const MoreListHeading = styled.h3`
  color: ${(props) => props.theme.color.light};
  font-size: 20px;
  margin-bottom: 12px;
`

const NavLink = styled(Link)`
  color: ${(props) => props.theme.color.light};
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 0;

  :hover {
    text-decoration: underline;
  }
`

const More = () => {

  return (
    <StaticQuery 
      query={`${moreQuery}`}
      render={(data) => {

        const prismicContent = data.allPrismicNavigationMore.edges[0]
        if (!prismicContent) return null 
        const doc = prismicContent.node 


        return (
          <MoreLinks>
            <MoreListHeading>
              Meer
            </MoreListHeading>
            <MoreList as="ul">
              {doc.data.more_navigation.map((navItem, index) => {

                return (
                  <li className="nav-item" key={`link-${index}`}>
                    <NavLink to={`../${navItem.link.uid}`}>
                      {navItem.link_label[0].text}
                    </NavLink>
                  </li>
                )
              })}
            </MoreList>
          </MoreLinks>
        )
      }}
    />
  )
}

const moreQuery = graphql`
  query moreQuery {
    allPrismicNavigationMore {
      edges {
        node {
          data {
            more_navigation {
              link {
                slug
                uid
              }
              link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default More