import React from 'react';
import LogoSource from '../images/logo_eilandbeheer.svg'
import { Link, graphql, StaticQuery } from 'gatsby'
import linkResolver from '../utils/linkResolver'
import { slide as SidebarMenu } from 'react-burger-menu'; 
import styled from "styled-components";


const StyledSidebarMenu = styled.nav`
  width: 100%;

  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 20px;
    right: 32px;
    top: 151px;

    @media ${(props) => props.theme.breakpoints.xxs} {
      top: 151px;
    }

    @media ${(props) => props.theme.breakpoints.s} {
      right: 78px;
      top: 108px;
    }

    @media ${(props) => props.theme.breakpoints.m} {
      right: 74px;
      top: 104px;
    }

    @media ${(props) => props.theme.breakpoints.l} {
      display: none;
    }
  }

  .bm-burger-bars {
    background: ${(props) => props.theme.color.dark};
    opacity: 1 !important;
  }

  .bm-cross {
    background: ${(props) => props.theme.color.dark};
    position: absolute;
    top: -7px;
    height: 24px !important;
    width: 3px;
    opacity: 1;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
    right: 32px !important;
    top: 33px !important;
  }

  .bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
  }

  .bm-menu {
    background: #ededed;
    font-size: 16px;
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
  }

  .SidebarList {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    position: fixed;
    top: 0;
    z-index: 1001 !important;
  }
`

const Logo = styled.img`
  margin: 0 16px;
  height: 90px;
  max-width: 160px;
`

const NavItem = styled.li`
  padding: 0 8px;

  @media ${(props) => props.theme.breakpoints.xxs} {
    padding: 4px 8px;
  }
`

const NavLink = styled(Link)`
  color: ${(props) => props.theme.color.dark};
  font-weight: 300;
  display: block;
  padding: 11px 12px;

  :hover,
  :focus {
    background-color: rgba(0,0,0,0.12);
  }

  :visited {
    color: rgba(0,0,0,0.87);
  }

  &.active {
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.color.secondary};
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }

`

const SidebarMenuHeading = styled.div``

const SiderbarButton = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 12px 0;

  @media ${(props) => props.theme.breakpoints.xxs} {
    padding: 20px 0;
  }
`

const CallNowLink = styled.a`
  background-color: ${(props) => props.theme.color.secondary};
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  margin: 12px;
  height: 36px;

  @media ${(props) => props.theme.breakpoints.m} {
    display: none;
  }
`

const Sidebar = () => {

  return (

    <StaticQuery 
      query={`${sidebarQuery}`}
      render={(data) => {

        const prismicContent = data.allPrismicNavigation.edges[0]
        if (!prismicContent) return null 
        const document = prismicContent.node

        return (
          <StyledSidebarMenu>
            <SidebarMenu>
              <SidebarMenuHeading>
                <Logo src={LogoSource} alt="Eilandbouw logo" />
              </SidebarMenuHeading>
              <ul className="SidebarList">
                {document.data.top_navigation.map((navItem, index) => {
                  const linkTo = linkResolver(navItem.link)
                  return (
                    <NavItem key={`link-${index}`}>
                      <NavLink 
                        activeClassName="active" 
                        to={linkTo} 
                      >
                        {navItem.link_label.text}
                      </NavLink>
                    </NavItem>
                    
                  )
                })}
              </ul>
              <SiderbarButton>
                <CallNowLink href="tel:+31(0)653406977">Bel nu</CallNowLink>
              </SiderbarButton>
            </SidebarMenu>
          </StyledSidebarMenu>
        )
      }}
    />
  )
}

const sidebarQuery = graphql`
  query sidebarQuery {
    allPrismicNavigation {
      edges {
        node {
          data {
            top_navigation {
              link {
                type
                uid
              }
              link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Sidebar 