import React from 'react'
import Theme from '../styles/theme'

import Grid from './layout/Grid'
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import Section from './layout/Section'
import Notice from './Notice'

import styled from "styled-components";

const StyledSection = styled(Section)`
  padding: 1rem 0;

  @media ${(props) => props.theme.breakpoints.xs} {
    padding: 3rem 0;
  }
`

const Layout = ({ children }) => (
  <Theme id="theme">
    <Grid id="grid">
      <Notice />
      <Header />
      <StyledSection as="main">
        {children}
      </StyledSection>
      <Footer />
    </Grid>
    <Sidebar pageWrapId={'grid'} outerContainerId={'theme'} />
  </Theme>
)

export default Layout
