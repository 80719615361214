import React from 'react'
import styled from 'styled-components'

import Section from './layout/Section'
import Container from './layout/Container'

const StyledSection = styled(Section)`
  background-color: #d81b60;
  position: fixed;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  z-index: 9999;
`

const StyledContainer = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Message = styled.p`
  color: #fff;
  grid-column: 1 / span 6;
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
    flex-direction: row;
  }

  a {
    color: #fff;
    display: inline;
    margin-left: 6px;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Notice = () => {

  return (
    <StyledSection>
      <StyledContainer>
        <Message>Deze website is ontwikkeld in opdracht van Eilandbeheer, 
        ben je op zoek naar een nieuwe website of wil je vrijblijvend advies? 
        <a href="https://stuif.design">Neem eens contact op</a>.</Message>
      </StyledContainer>
    </StyledSection>
  )
}

export default Notice