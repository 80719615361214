import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  breakpoints: {
    xxs: "only screen and (min-width: 360px)",
    xs: "only screen and (min-width: 400px)",
    s: "only screen and (min-width: 600px)",
    m: "only screen and (min-width: 1024px)",
    l: "only screen and (min-width: 1280px)",
    xl: "only screen and (min-width: 1440px)",
    xxl: "only screen and (min-width: 1920px)",
  },
  color: {
    primary: "rgba(255,222,10,1)",
    secondary: "rgba(0,150,184,1)",
    secondary_lighter: "rgba(0,167,204,1)",
    dark: "rgba(0,0,0,0.87)",
    light: "rgba(0,0,0,0.6)",
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;