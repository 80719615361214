import React from 'react'
import LogoSource from '../images/logo_eilandbeheer.svg'
import { Link, graphql, StaticQuery } from 'gatsby'
import linkResolver from '../utils/linkResolver'
import styled from 'styled-components'

import Section from './layout/Section'
import Container from './layout/Container'

const StyledSection = styled(Section)`
  margin-top: 116px;
  padding: 0;

  @media ${(props) => props.theme.breakpoints.xxs} {
    margin-top: 95;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    margin-top: 74px;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    margin-top: 53px;
  }
`

const BrandLogo = styled(Link)`
  display: flex;
  grid-column: 2 / span 3;
  height: 90px;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 3;
    height: auto;
  }
`

const Logo = styled.img`
  max-width: 160px;

  @media ${(props) => props.theme.breakpoints.s} {
    max-width: 220px;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    max-width: 240px;
  }
`
 
const Nav = styled.nav`
  display: none;
  align-items: center;
  padding-left: 0;
  list-style: none;
  height: 120px;

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 6 / span 7;
    display: flex;
    justify-content: flex-end;
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
  }
`

const Header = () => {

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {

        const prismicContent = data.allPrismicNavigation.edges[0]
        if (!prismicContent) return null
        const doc = prismicContent.node

        return (
          <StyledSection as="header">
            <Container>
              <BrandLogo to="/">
                <Logo src={LogoSource} alt="Eilandbouw logo" />
              </BrandLogo>
              <Nav>
                <ul className="nav-list">
                  {doc.data.top_navigation.map((navItem, index) => {
                    const linkTo = linkResolver(navItem.link)
                    return (
                      <li className="nav-item" key={`link-${index}`}>
                        <Link 
                          className="nav-link" 
                          to={linkTo} 
                        >
                          {navItem.link_label.text}
                        </Link>
                      </li>
                      
                    )
                  })}
                </ul>
              </Nav>
            </Container>
          </StyledSection>
        )
      }}
    />
  )
}

const headerQuery = graphql`
  query headerQuery {
    allPrismicNavigation {
      edges {
        node {
          data {
            top_navigation {
              link {
                type
                uid
                url
              }
              link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Header
