import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'


const ServicesLinks = styled.div`
  grid-column: 1 / span 3;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 3;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 3;
  }
`

const ServicesList = styled.div`
  display: flex;
  flex-direction: column;
`

const ServicesListHeading = styled.h3`
  color: ${(props) => props.theme.color.light};
  font-size: 20px;
  margin-bottom: 12px;
`

const NavLink = styled(Link)`
  color: ${(props) => props.theme.color.light};
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 0;

  :hover {
    text-decoration: underline;
  }
`

const Services = () => {

  return (
    <StaticQuery
      query={`${servicesQuery}`}
      render={(data) => {

        const prismicContent = data.allPrismicNavigationServices.edges[0]
        if (!prismicContent) return null
        const doc = prismicContent.node 

        return(
          <ServicesLinks>
            <ServicesListHeading>
              Services 
            </ServicesListHeading>
            <ServicesList as="ul">
              {doc.data.services_navigation.map((navItem, index) => {
                  return (
                    <li className="nav-item" key={`link-${index}`}>
                      <NavLink to={`../${navItem.link.uid}`}>
                        {navItem.link_label[0].text}
                      </NavLink>
                    </li>
                  )
                })}
            </ServicesList>
          </ServicesLinks>
        )
      }}
    />
  )
}

const servicesQuery = graphql`
  query servicesQuery {
    allPrismicNavigationServices {
      edges {
        node {
          data {
            services_navigation {
              link {
                type
                uid
              }
              link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Services